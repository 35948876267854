html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
ul {
  list-style: none;
}
button,
input,
select {
  margin: 0;
}
html {
  box-sizing: border-box;
  font-family: "Metropolis", sans-serif;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
img,
video {
  height: auto;
  max-width: 100%;
}
iframe {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
div, main, footer, session {
  display: flex;
  /* flex-direction: column; */
}
a {
  cursor: pointer;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #32c0a9!important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  color: #35675e!important;
  background-color: #35675e!important;
}

.MuiSwitch-track {
  background-color: #8e8787!important;
}